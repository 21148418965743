import React, { useState } from 'react';

import Head from 'next/head';
import dynamic from 'next/dynamic';

const Feed = dynamic(() => import('@components/feedMain'));
const Layout = dynamic(() => import('@components/global/main'));

export default function HomePage() {
  const [rerender, setRerender] = useState(false);
  // const [userData, setUserData] = useState(null);

  const [receivedBookPosts, setReceivedBookPosts] = useState(null);
  const [receivedBookIds, setReceivedBookIds] = useState(null);

  return (
    <>
      <Head>
        <title>Karent App - Socializing is the way</title>
        <meta
          name="description"
          content="Karent connects members of a community to enable sharing of ideas and content through text and pictures"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1 maximum-scale=1"
        />
        <meta
          name="og:description"
          content="Karent connects members of a community to enable sharing of ideas and content through text and pictures"
        />
        <link
          rel="icon"
          href="https://res.cloudinary.com/dpnbddror/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,b_rgb:262c35/v1637870721/brand_assets/LOGO_Karent_tx5hn5.png"
        />
        <meta
          property="og:title"
          content="Karent App - Socializing is the way"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://karent.app/" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dpnbddror/image/upload/v1637870721/brand_assets/LOGO_Karent_BG1_ykujsq.png"
        />
        <meta name="twitter:card" content="summary" />
        <meta property="twitter:domain" content="karent.app" />
        <meta property="twitter:url" content="https://karent.app" />
        <meta name="twitter:creator" content="@karent" />
        <meta
          name="twitter:title"
          content="Karent App - Socializing is the way"
        />
        <meta
          name="twitter:description"
          content="Karent connects members of a community to enable sharing of ideas and content through text and pictures"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/dpnbddror/image/upload/v1637870721/brand_assets/LOGO_Karent_BG1_ykujsq.png"
        />
      </Head>
      <Layout
        // sendToPage={(val) => setUserData(val)}
        renderFromCreate={(val) => setRerender(val)}
        fetchedBookMarkPosts={(val) => setReceivedBookPosts(val)}
        fetchedBookMarks={(val) => setReceivedBookIds(val)}
      >
        <Feed
          // userinfo={userData}
          setDonee={(val) => setRerender(val)}
          rere={rerender}
          receivedBookPosts={receivedBookPosts}
          receivedBookIds={receivedBookIds}
        />
      </Layout>
    </>
  );
}
